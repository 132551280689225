<template>
  <div>

    <v-autocomplete
        :value="newValue"
        :items="items"
        :loading="loading"
        :search-input="searchInput"
        @change="select"
        :hide-no-data="true"
        hide-details
        no-data-text="No Results Found"
        placeholder="Type to Search"
        return-object
        item-text="AutocompleteDisplay"
        item-value="key"
        outlined
        autofocus
        :error="errors && errors.length > 0"
        
        :disabled="disabled || loadingSelected"
        :label="required ? label + '*' : label"

        :item-disabled="itemDisabled"
    >
    
      <template v-slot:item="{ item }">
        <v-list-item-content>
          {{item.AutocompleteDisplay}}
          <v-list-item-subtitle>{{item.Email}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action style="text-align: right; flex-direction: row;">

          <v-btn color="secondary" type="button" icon x-small height="30" width="30" class="ml-1" title="Remove" style="display: inline-block; pointer-events: all;" 
          v-if="isPreferredKey(item.id) && item.id != defaultKey && (!newValue || (newValue && item.id != newValue.id))" :disabled="false"
            @click.stop="removePreferredKey(item.id)">
              <i class="fal fa-times" style="font-size: 20px;"></i>
          </v-btn>

          <v-btn color="#fdb714" type="button" icon x-small height="30" width="30" class="ml-1" title="Set as Default" style="display: inline-block; pointer-events: all;" 
          v-if="isPreferredKey(item.id) && !simple && showDefaultToggle"
            @click.stop="toggleDefault(item.id)">
              <div v-show="item.id == defaultKey">
                <i class="fas fa-star" style="font-size: 20px;"></i>
              </div>
              <div v-show="item.id != defaultKey">
                <i class="fal fa-star" style="font-size: 20px;"></i>
              </div>
          </v-btn>

          <div icon x-small height="30" width="30" title="Default Underwriter" style="color: #fdb714 ;display: inline-block; pointer-events: all; padding-right: .25em;"
          v-if="item.id == defaultKey && !simple && !showDefaultToggle">
            <i class="fas fa-star" style="font-size: 20px;"></i>
          </div>

        </v-list-item-action>
      </template>
        
    
    </v-autocomplete>

    <p class="footnote" v-if="footnote" v-html="footnote"></p>

    <ErrorMessages :errors="errors"></ErrorMessages>

    <v-alert dense type="error" v-if="invalidSelection" class="my-2" key="invalidSelection">
      The previously selected underwriter is no longer available. Please select a different underwriter.
    </v-alert>

  </div>
</template>

<script>
import { UnderwriterService } from '@/api/UnderwriterService'
import { AgentService } from '@/api/AgentService'
import { AgencyService } from '@/api/AgencyService'
import { sortUnderwritersByDistance } from '@/scripts/geolocation-underwriters'

export default {
  name: 'UnderwriterAutocompleteTypeAhead',
  data() {
    return {
      newValue: null,
      loading: false,
      timeout: null,
      profile: null,
      items: [],
      searchInput: null,
      defaultKey: null,
      preferredKeys: [],
      service: null,
      updateDefaultAction: null,
      addPreferredAction: null,
      removePreferredAction: null,
      riskState: null,
      agencyOrgBranchId: null,
      loadingSelected: false,
      invalidSelection: false,
      footnote: 'To select a different underwriter, please enter their full name or location.'
    }
  },
  props: {
    value: Object,
    rules: String,
    label: String,
    clearable: {
      type: Boolean,
      default: false
    },
    required: Boolean,
    errors: Array,
    disabled: Boolean,
    simple: {
      type: Boolean,
      default: false
    },
    autoselectDefault: {
      type: Boolean,
      default: true
    },
    showDefaultToggle: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'Underwriter'
    },
    salesChannel: {
      type: String
    }
  },
  emits: [
    'change'
  ],
  created() {
    if (this.mode == 'Underwriter') {
      this.service = UnderwriterService;
      this.updateDefaultAction = 'updateDefaultUnderwriterKey';
      this.addPreferredAction = 'addPreferredUnderwriterKey';
      this.removePreferredAction = 'removePreferredUnderwriterKey';
      this.defaultGetter = 'getDefaultUnderwriterKey';
      this.preferredGetter = 'getPreferredUnderwriterKeys';
    } else if (this.mode == 'Agent') {
      this.service = AgentService;
      this.updateDefaultAction = 'updateDefaultAgentKey';
      this.addPreferredAction = 'addPreferredAgentKey';
      this.removePreferredAction = 'removePreferredAgentKey';
      this.defaultGetter = 'getDefaultAgentKey';
      this.preferredGetter = 'getPreferredAgentKeys';
    }

    this.initialize();
  },
  methods: {
    initialize() {
      if (this.newValue && this.newValue.id && this.salesChannel != 'BindV1') {
        this.loadSelected(this.newValue.id);
      }

      if (!this.simple) {

        this.$store.watch(() => {
          if (this.salesChannel == 'BindV1') {
            this.loadFlood();
          } else {         
            if (this.mode == 'Agent') {
              const risk = this.$store.getters.getControlValue('additionalForm', 'Risk');
              if (risk) this.riskState = risk.State;

              const agency = this.$store.getters.getControlValue('additionalForm', 'Agency');
              if (agency) this.agencyOrgBranchId = agency.OrgBranchId;
            }

            this.defaultKey = this.$store.getters[this.defaultGetter];
            if (this.preferredKeys.length != this.$store.getters[this.preferredGetter].length) {
              this.preferredKeys = this.$store.getters[this.preferredGetter];
              this.loadPreferred();
            }

            if (this.defaultKey != this.$store.getters[this.defaultGetter]) {
              this.loadDefault();
            }

            const userMismatch = this.$store.getters.getUserProfile?.id !== this.profile?.id
            if (((!this.profile || !this.profile.id) && this.$store.getters.getUserProfile) || userMismatch) {
              this.profile = this.$store.getters.getUserProfile;
              this.defaultKey = this.$store.getters[this.defaultGetter];
              if ((!this.newValue && this.defaultKey && this.autoselectDefault)) {
                this.setDefaultAsSelected();
              }
            } else {
              this.profile = this.$store.getters.getUserProfile;
            }
          }

          this.$forceUpdate();
        },
          () => { },
          {
            immediate: true,
            deep: true
          });
      }
    },
    fullSort(){
      const agent = this.agentProfile;

      if (this.isAgent && agent != null && agent.Postal != null){
        const sortedUnderwriters = sortUnderwritersByDistance(this.underwriters, this.agentProfile.Postal);
        this.items = sortedUnderwriters;
      } else {
        this.underwriters.forEach(u => {
          this.addItem(u);
        });
      }
      this.sortPreferredItems();
    },
    itemDisabled(item) {
      if (this.mode == 'Agent') {
        return !this.agentIsSelectable(item);
      }
      return false;
    },
    select(event) {
      if (event) {
        this.newValue = event;
        if (!this.simple){
          this.sortPreferredItems();
          this.$store.dispatch(this.addPreferredAction, event.id)
        } 
      } else {
        this.newValue = null;
      }

      this.emitValue();
    },
    isPreferredKey(key) {
      return this.preferredKeys.indexOf(key) > -1;
    },
    removePreferredKey(key) {
      this.preferredKeys = this.preferredKeys.filter(i => i != key);
      this.$store.dispatch(this.removePreferredAction, key)

      if (key == this.defaultKey && !this.simple) {
        this.toggleDefault(key);
      }

      this.fullSort();
      this.$forceUpdate();
    },
    async loadPreferred() {
      if (!this.preferredKeys.length || typeof (this.preferredKeys) != 'object') return;

      let promises = []

      this.preferredKeys.forEach((key) => {
        if (this.items.includes(i => i.id == key)) return;

        if (key) {
          promises.push(this.service.get({
            id: key
          }, {
            handleError: false
          }).then((data) => {
            if (data && data.Active) {
              this.addItem(data);
            } else {
              this.removePreferredKey(key);
            }
          }).catch(() => {
            this.removePreferredKey(key);
          }))
        }
      })

      await Promise.all(promises);
    },
    async loadFlood() {
      let agency = this.$store.getters.getControlValue('additionalForm', 'Agency');

      this.footnote = "";

      if (!agency && this.isAgent) {
        const id = this.user.profile.org_branch_id
        if (id) {
          await AgencyService.get({ id: id })
            .then((response) => {
              if (response) {
                agency = response
              }
            })
        }
      }
      
      if (agency) {
        await this.service.getFlood({
          state: agency.State
        }, {
          handleError: false
        }).then((data) => {
          if (data && data.Active) {
            this.items = []
            this.addItem(data);
            this.newValue = data;
            this.emitValue();
          }
        }).catch((e) => {
          console.log('e', e)
        })
      }
    },
    agentIsSelectable(agent) {
      return agent && agent.Licenses && agent.Licenses.some( (l) => l.State == this.riskState ) && agent.OrgBranchId == this.agencyOrgBranchId
    },
    setDefaultAsSelected() {
      if (!this.defaultKey) return;

      if (this.mode == 'Agent') {
        this.service.get({
          id: this.defaultKey
        }, {
          handleError: false
        }).then((data) => {
          if (this.agentIsSelectable(data)) {
            this.loadSelected(this.defaultKey)
          }
        })

      } else {
        this.loadSelected(this.defaultKey)
      }
    },
    loadSelected(key) {
      if (!key) return;
      this.loadingSelected = true;
      this.$store.dispatch(this.addPreferredAction, key)
      this.service.get({
        id: key
      }, {
        handleError: false
      }).then((data) => {
        if (!data || !data.Active) {
          this.newValue = null;
          this.invalidSelection = true;
        } else {
          this.addItem(data);
          this.newValue = data;
        }
        
        this.emitValue();
        this.loadingSelected = false;
      }).catch(() => {
        this.newValue = null;
        this.emitValue();
        this.loadingSelected = false;
      })
    },
    loadDefault() {
        if (!this.defaultKey) return;
        this.loadingSelected = true;
        this.service.get({
          id: this.defaultKey
        }, {
          handleError: false
        }).then((data) => {
          if (data && data.Active) {
            this.addItem(data);
          }
          this.loadingSelected = false;
        }).catch(() => {
          this.loadingSelected = false;
        });
    },
    addItem(u) {
      if (this.items.find(i => i.id == u.id)) return;
      this.items.push(u);
    },
    sortPreferredItems() {
      const index = this.items.findIndex(uw => uw.HumanId === 275473);
      let SPOC = [];
      if (index != -1){
        SPOC = this.items.splice(index, 1);
      }
      const preferredUnderwriters = this.items.filter((uw) => this.preferredKeys.includes(uw.id));
      const underwriters = this.items.filter((uw) => !this.preferredKeys.includes(uw.id));

      this.items = preferredUnderwriters.concat(underwriters).concat(SPOC);

      if (!this.defaultKey) return;
      const defaultIndex = this.items.findIndex(x => x.id === this.defaultKey)
      const defaultUnderwriter = this.items.splice(defaultIndex, 1);
      this.items = defaultUnderwriter.concat(this.items);
    },
    toggleDefault(key) {
      if (key == this.defaultKey) {
        //key = null;
        //prevent removing a default, must select a new one
        return;
      }
      this.$store.dispatch(this.updateDefaultAction, key)
    },
    emitValue() {
      if (this.newValue) this.invalidSelection = false;
      const v = this.newValue ? {...this.newValue} : null;
      this.$emit('change', v)
    },
  },
  computed: {  
    user() {
      return this.$store.getters.getUser;
    },
    isAgent() {
      return this.$store.getters.getUserRole == 'agent';
    },
    underwriters() {
      return this.$store.getters['resource/getUnderwriters']
    },
    agentProfile() {
      return this.$store.getters.getAgentProfile;
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal && (!this.newValue || newVal.id != this.newValue.id) && newVal.id) {
          this.newValue = newVal;
          this.addItem(newVal);
        }
        
      }
    },
    preferredKeys: {
      immediate: true,
      handler(newVal){
        this.fullSort();
      }
    },
    '$store.getters.getUser'() {
      this.initialize()
    },
    underwriters : {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.fullSort();
      }
    }
  }
}
</script>

<style scoped>
  ::v-deep .v-input {
    background-color: white;
  }
</style>