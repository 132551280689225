const zips = require("zips");

export const sortUnderwritersByDistance = (underwriters: any[], zip: string) => {
  const agentGeolocation = zips.getByZipCode(zip);

  let inState = underwriters.filter((underwriter) => underwriter.State === agentGeolocation.state);
  let outOfState = underwriters.filter((underwriter) => underwriter.State !== agentGeolocation.state);

  if (!Array.isArray(inState) || inState.length > 1){
    inState = inState.map(inStateUnderwriter => ({
      ...inStateUnderwriter,
      Distance: getDistance(agentGeolocation.lat, agentGeolocation.long, inStateUnderwriter.GeoLatitude, inStateUnderwriter.GeoLongitude)
    })).sort((a, b) => a.Distance - b.Distance);
  }

  outOfState = outOfState.map(outOfStateUnderwriter => ({
    ...outOfStateUnderwriter,
    Distance: getDistance(agentGeolocation.lat, agentGeolocation.long, outOfStateUnderwriter.GeoLatitude, outOfStateUnderwriter.GeoLongitude)
  })).sort((a, b) => a.Distance - b.Distance);

  const sortedUnderwriters = inState.concat(outOfState);

  return sortedUnderwriters;
}

function getDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371; // Earth's radius in kilometers. Change to
                  // miles if we ever display distance.

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
}

function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}